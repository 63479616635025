<template>
  <tr>
    <td width="30%">{{ data.nama_produk }}</td>
    <td>{{ data.nama_vendor }}</td>
    <td>{{ (data.qrcode && data.qrcode.code) || "-" }}</td>
    <td>{{ (data.anggota && data.anggota.nama_lengkap) || "-" }}</td>
    <td width="20%">
      <div class="btn-group" role="group" aria-label="Action menu">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="assignToMember(data)"
          v-if="!data.anggota"
        >
          <i class="bi bi-check2-all"></i> Terapkan ke Anggota
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="unclaimMerchandise(data.id)"
          v-if="data.anggota"
        >
          <i class="bi bi-trash"></i> Lepas Merchandise
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TableItem",
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    assignToMember(data) {
      this.$emit("assignToMember", data);
    },
    unclaimMerchandise(id) {
      this.$emit("unclaimMerchandise", id);
    },
  },
};
</script>

<style scoped></style>
