<template>
  <div class="merchandise">
    <h1 class="mb-5">Claim Merchandise</h1>
    <div class="row">
      <!-- Card -->
      <div class="col-md-12">
        <div class="card">
          <h5
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            Daftar Claim Merchandise
          </h5>
          <div class="card-body table-responsive">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Cari"
                aria-label="Cari"
                aria-describedby="button-addon2"
                v-model="search"
                @keypress.enter="searchData"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="searchData"
              >
                Cari
              </button>
            </div>
            <transition name="fade">
              <div class="text-center" v-if="isLoading">
                <div class="spinner-grow" role="status"></div>
              </div>
              <Table
                v-else
                :headers="headers"
                :items="merchandise"
                @assignToMember="assignToMember"
                @unclaimMerchandise="unclaimMerchandise"
              />
            </transition>
            <Pagination
              v-if="merchandise.length"
              :currentPage="currentPage"
              :totalData="totalData"
              :totalPage="totalPage"
              @nextPage="nextPage"
              @previousPage="previousPage"
              @assignToMember="assignToMember"
              @unclaimMerchandise="unclaimMerchandise"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Assign to Anggota -->
    <assign-modal
      :showModal="showAssignModal"
      :editedIndex="editedAssignIndex"
      :editedId="editedAssignId"
      :editedItem="editedAssignItem"
      :initialAnggota="anggota"
      @closeModal="closeForm"
      @save="assign"
    />
  </div>
</template>

<script>
import { merchandiseService } from "@/services";
import Table from "./components/Table.vue";
import Pagination from "./components/Pagination.vue";
import AssignModal from "./components/AssignModal.vue";

export default {
  components: {
    Table,
    Pagination,
    AssignModal,
  },
  data() {
    return {
      // Search query
      search: "",
      limit: 10,
      sort: "updatedAt",
      order: "desc",

      // Pagination
      totalData: 0,
      currentPage: 1,

      // Loading State
      isLoading: false,

      // Data Array
      headers: [
        "Nama Produk",
        "Nama Vendor",
        "Kode QR",
        "Dimiliki Oleh",
        "Tindakan",
      ],
      merchandise: [],
      anggota: [],

      // Modal state
      showAssignModal: false,

      // Default form assign state
      editedAssignIndex: -1,
      editedAssignId: 0,
      editedAssignItem: {
        anggota_id: "",
      },
      defaultAssignItem: {
        anggota_id: "",
      },
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  methods: {
    // Search & Load
    searchData() {
      this.currentPage = 1;
      this.isLoading = true;
      this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    async loadData(limit, search, page, sort, order) {
      const resp = await merchandiseService.getAll(
        limit,
        search,
        page,
        sort,
        order
      );
      this.merchandise = resp.rows;
      this.totalData = resp.count;
      this.currentPage = page;
      this.isLoading = false;
    },

    // Pagination
    previousPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage - 1,
        this.sort,
        this.order
      );
    },
    nextPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage + 1,
        this.sort,
        this.order
      );
    },

    // Modal
    closeForm() {
      this.showAssignModal = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    assignToMember(item) {
      this.editedAssignIndex = this.merchandise.indexOf(item);
      this.editedAssignId = item.id;
      this.editedAssignItem = Object.assign({}, item);
      this.showAssignModal = true;
    },
    async assign() {
      await merchandiseService.assignToMember({
        id: this.editedAssignId,
        anggota_id: this.editedAssignItem.anggota_id,
      });
      this.closeForm();
      await this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    async unclaimMerchandise(id) {
      try {
        await merchandiseService.unclaimMerchandise(id);
        this.$toast.success(`Merchandise berhasil dilepas.`);
      } catch (error) {
        this.$toast.error(`Merchandise Gagal dilepas. Coba lagi nanti.`);
      }
      await this.searchData();
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData(
      this.limit,
      this.search,
      this.currentPage,
      this.sort,
      this.order
    );
  },
};
</script>

<style scoped>
.merchandise {
  padding: 3rem 1.5rem;
}
</style>
