<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!items.length">
        <td colspan="5" class="text-center">
          <span>Tidak ada data ditemukan.</span>
        </td>
      </tr>
      <TableItem
        v-for="item in items"
        :key="item.id"
        :data="item"
        @assignToMember="assignToMember"
        @unclaimMerchandise="unclaimMerchandise"
      />
    </tbody>
  </table>
</template>

<script>
import TableItem from "./TableItem.vue";
export default {
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  components: {
    TableItem,
  },
  methods: {
    assignToMember(data) {
      this.$emit("assignToMember", data);
    },
    unclaimMerchandise(id) {
      this.$emit("unclaimMerchandise", id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
